<template>
  <div class="main-content">
    <avue-crud
        class="aVueCrud"
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom10 flex-wrap">


          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">使用时间: </span>
            <div class="width300">
              <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="~"
                  @change="handleDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <el-input size="medium" v-model="searchData.name" :maxlength="100" clearable placeholder="用户名称/手机号/id" class="marginRight10 width200 marginBottom10"/>
          <el-input size="medium" v-model="searchData.useObject" :maxlength="100" clearable placeholder="使用对象" class="marginRight10 width200 marginBottom10"/>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.type" clearable >
              <el-option value="" label="使用科目" />
              <el-option :value="1" label="消费" />
              <el-option :value="2" label="提现" />
            </el-select>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="aVueCrudNum">使用蜜豆积分：{{ num }}</div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import {list, listNum} from '@/api/honey/data'
export default {
  name: "index",
  data(){
    return{
      date: [], // 时间段
      searchData: {
        createTimeStart: '',
        createTimeEnd: '',
        name: '',
        useObject: '',
        type: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'蜜豆数据统计',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menu: false,
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname'
          },
          {
            label: '用户手机号',
            prop: 'phone'
          },
          {
            label: '用户id',
            prop: 'code'
          },
          {
            label: '用户姓名',
            prop: 'username'
          },
          {
            label: '使用对象',
            prop: 'useObject'
          },
          {
            label: '使用科目',
            prop: 'type',
            formatter: function(row, column, cellValue){
              return cellValue === 1 ? '消费' : '提现'
            }
          },
          {
            label: '使用蜜豆积分',
            prop: 'num'
          },
          {
            label: '使用时间',
            prop: 'createTime'
          }
        ]
      },
      num: 0
    }
  },
  mounted() {
    this.getListNum()
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    getListNum(){
      listNum({ ...this.searchData }).then(res => {
        console.log(res)
        if(res.code === 200){
          this.num = res.data.num;
        }
      })
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
      this.getListNum()
    },
    // 选择时间
    handleDate(val){
      if(val.length > 0){
        this.searchData.createTimeStart = val[0] + ' 00:00:00'
        this.searchData.createTimeEnd = val[1] + ' 23:59:59'
      }else{
        this.searchData.createTimeStart = ''
        this.searchData.createTimeEnd = ''
      }
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        createTimeStart: '',
        createTimeEnd: '',
        name: '',
        useObject: '',
        type: ''
      }
      this.date = [];
      this.page.currentPage = 1;
      this.onLoad()
      this.getListNum()
    },
    // 导出
    handleExport(){
      // console.log('导出')
      this.$confirm('确定导出数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = `/api/web/honey/data/export?createTimeStart=${this.searchData.createTimeStart}&createTimeEnd=${this.searchData.createTimeEnd}&name=${this.searchData.name}&useObject=${this.searchData.useObject}&type=${this.searchData.type}`;
        let date = new Date();
        let time = date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate() + '' + date.getHours() + '' + date.getMinutes() + '' + date.getSeconds();
        this.global.exportExcel(url, `蜜豆数据统计  -${time}.xlsx`);
      })
    }
  }
}
</script>

<style scoped>
.aVueCrud{
  position: relative;
}
.aVueCrudNum{
  position: absolute;
  left: 20px;
  bottom: 40px;
  font-size: 13px;
  font-weight: 400;
  color: #606266;
  line-height: 28px;
}
</style>